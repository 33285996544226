import { useParams } from "react-router";
import { useState, useEffect, Fragment, useCallback } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Stack,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Fab,
  Button,
} from "@mui/material";
import LoadingSpinner from "./LoadingSpinner";
import { Input } from "../stories/Input/Input.jsx";
import {
  Add as AddIcon,
  CloseOutlined as CloseOutlinedIcon,
  ArrowBackIosNew as ArrowBackIosNewIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import axios from "axios";
import DropFileInput from "./DropFileInput/DropFileInput";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

function UploadFile() {
  const [isLoading, setIsLoading] = useState(false);
  const { ProductId } = useParams();
  const [Product, SetProduct] = useState([]);
  const [Version, SetVersion] = useState({ id: "", apkfile: {}, version: "" });
  const [Versions, SetVersions] = useState([]);
  const itemsPerPage = 5;
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);

  const GetProduct = useCallback(async () => {
    setIsLoading(true);
    axios.defaults.headers.common["Authorization"] =
      sessionStorage.getItem("token");
    await axios
      .get(`${process.env.REACT_APP_APIURL}/product/${ProductId}`, {}, {})
      .then(function (response) {
        if (response.data.status === 1) {
          SetProduct(response.data.result);
        }
        setIsLoading(false);
      })
      .catch(function (error) {
        toast.error(error.response.data.error.message);
        setIsLoading(false);
      });
  }, [ProductId]);

  const GetAppVersion = useCallback(
    async (skip = 0) => {
      setIsLoading(true);
      axios.defaults.headers.common["Authorization"] =
        sessionStorage.getItem("token");
      await axios
        .get(
          `${process.env.REACT_APP_APIURL}/product-versions/${ProductId}/${itemsPerPage}/${skip}`
        )
        .then(function (response) {
          if (response.data.status === 1) {
            SetVersions(response.data.result.Version);
            setPageCount(Math.ceil(response.data.result.Count / itemsPerPage));
          }
          setIsLoading(false);
        })
        .catch(function (error) {
          toast.error(error.response.data.error.message);
          setIsLoading(false);
        });
    },
    [ProductId]
  );

  const handlePageClick = (event, value) => {
    GetAppVersion((value - 1) * itemsPerPage);
    setPage(value);
  };

  useEffect(() => {
    GetProduct();
    GetAppVersion();
  }, [GetAppVersion, GetProduct]);

  const handleClickOpen = () => {
    setOpen(true);
    SetVersion({ ...Product, id: "", apkfile: {}, version: "" });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputs = (event) => {
    SetVersion({ ...Version, [event.target.name]: event.target.value });
  };

  const handleFiles = (files) => {
    SetVersion({ ...Version, apkfile: files.length > 0 ? files[0] : {} });
  };

  const Save = async () => {
    const url = Version.id
      ? `updateversion/${Version.id}`
      : `saveversion/${ProductId}`;
    let formData = new FormData();
    formData.append("apkfile", Version.apkfile);
    formData.append("version", Version.version);

    setIsLoading(true);
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_APIURL}/${url}`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: sessionStorage.getItem("token"),
      },
    })
      .then(async function (response) {
        if (response.data.status === 1) {
          console.log("updateversion API", response);
          if (response.data.result) {
            console.log(Product);
            SetProduct({
              ...Product,
              version: response.data.result,
            });
          }
          toast.success(response.data.message);
          handleClose();
          SetVersion({ ...Version, apkfile: {}, version: "" });
        } else toast.warn(response.data.message);
        setIsLoading(false);
        await GetAppVersion();
      })
      .catch(function (error) {
        toast.error(error.response.data.error.message);
        setIsLoading(false);
      });
  };

  const Release = async (Id) => {
    setIsLoading(true);
    axios.defaults.headers.common["Authorization"] =
      sessionStorage.getItem("token");
    await axios
      .put(`${process.env.REACT_APP_APIURL}/release-version/${Id}`, {}, {})
      .then(function (response) {
        if (response.data.status === 1) {
          toast.success(response.data.message);
          GetProduct();
        } else toast.warn(response.data.message);
        setIsLoading(false);
      })
      .catch(function (error) {
        toast.error(error.response.data.error.message);
        setIsLoading(false);
      });
  };

  const View = async (Id) => {
    setIsLoading(true);
    axios.defaults.headers.common["Authorization"] =
      sessionStorage.getItem("token");
    await axios
      .get(`${process.env.REACT_APP_APIURL}/product-version/${Id}`, {}, {})
      .then(function (response) {
        if (response.data.status === 1) {
          setOpen(true);
          SetVersion(response.data.result);
        } else toast.warn(response.data.message);
        setIsLoading(false);
      })
      .catch(function (error) {
        toast.error(error.response.data.error.message);
        setIsLoading(false);
      });
  };

  const Delete = async (Id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this version?",
      type: "question",
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes',
      cancelButtonText: "No",
      closeOnConfirm: false,
      closeOnCancel: false
    }).then(async function (isConfirm) {
      if (isConfirm.value === true) {
        setIsLoading(true);
        axios.defaults.headers.common["Authorization"] =
          sessionStorage.getItem("token");
        await axios
          .delete(`${process.env.REACT_APP_APIURL}/product-version/${Id}`, {}, {})
          .then(async function (response) {
            if (response.data.status === 1) toast.success(response.data.message);
            else toast.warn(response.data.message);
            setIsLoading(false);
            await GetAppVersion();
          })
          .catch(function (error) {
            toast.error(error.response.data.error.message);
            setIsLoading(false);
          });
      }
    });
  };

  return (
    <>
      <Box className="launcher">
        {isLoading ? <LoadingSpinner /> : <></>}

        <Box className="row">
          <Box className="col-xl-4 col-md-4 col-12">
            <a
              href="#/appstore"
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
              className="btitle"
            >
              <ArrowBackIosNewIcon />
              Back
            </a>
          </Box>
        </Box>
        <Box className="contentbox">
          <Box className="topbox">
            <Box className="row">
              <Box className="col-xl-4 col-md-4 col-12">
                <Typography className="fw-bold">Package Name</Typography>
                <p>{Product.packagename}</p>
              </Box>
              <Box className="col-xl-4 col-md-4 col-12">
                <Typography className="fw-bold">Release Version</Typography>
                <p>{Product.version}</p>
              </Box>
              <Box className="col-xl-4 col-md-4 col-12">
                <Typography className="fw-bold">Description</Typography>
                <p>{Product.description}</p>
              </Box>
            </Box>
            <Box className="deviceblock">
              <Box className="row">
                <Box className="col-xl-4 col-md-4 col-12">
                  <Typography className="fw-bold">Device</Typography>
                  <p>{Product.device}</p>
                </Box>
                <Box className="col-xl-4 col-md-4 col-12">
                  <Typography className="fw-bold">Category</Typography>
                  <p>{Product.category}</p>
                </Box>
                <Box className="col-xl-4 col-md-4 col-12">
                  <Typography className="fw-bold">Layer</Typography>
                  <p>{Product.layer}</p>
                </Box>
              </Box>
            </Box>
          </Box>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              className="tablebox table-bordered"
            >
              <TableHead sx={{ background: "#d7dadb" }}>
                <TableRow>
                  <TableCell sx={{ color: "#000", fontSize: "16px" }}>
                    Name
                  </TableCell>
                  <TableCell sx={{ color: "#000", fontSize: "16px" }}>
                    Version
                  </TableCell>
                  <TableCell sx={{ color: "#000", fontSize: "16px" }}>
                    Unique Id
                  </TableCell>
                  <TableCell sx={{ color: "#000", fontSize: "16px" }}>
                    Added Date
                  </TableCell>
                  <TableCell sx={{ color: "#000", fontSize: "16px" }}>
                    Release
                  </TableCell>
                  <TableCell sx={{ color: "#000", fontSize: "16px" }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Versions.map((version, index) => (
                  <TableRow
                    key={"version" + index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell scope="row">{Product.name}</TableCell>
                    <TableCell>{version.version}</TableCell>
                    <TableCell>{version.id}</TableCell>
                    <TableCell>
                      {new Date(version.updateddate).toLocaleString()}
                    </TableCell>
                    <TableCell>
                      <button
                        onClick={() => {
                          Release(version.id);
                        }}
                        className={
                          version.version === Product.version
                            ? "tablebtn release-active"
                            : "tablebtn"
                        }
                      >
                        RELEASE
                      </button>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          View(version.id);
                        }}
                        style={{ color: "#22abe1" }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          Delete(version.id);
                        }}
                        style={{ color: "#22abe1" }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Stack spacing={2} sx={{ float: "left", marginTop: "15px" }}>
            {pageCount > 1 ? (
              <Pagination
                count={pageCount}
                page={page}
                onChange={handlePageClick}
              />
            ) : (
              <></>
            )}
          </Stack>
        </Box>

        <Fragment>
          <Dialog fullWidth={true} maxWidth={"md"} open={open}>
            <DialogTitle sx={{ borderBottom: "1px solid #ddd" }}>
              Add New Version
              <IconButton
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "#1976d2",
                }}
              >
                <CloseOutlinedIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Box sx={{ width: "100%" }}>
                <div className="row mt-3">
                  <div className="col-xl-12 col-md-12 col-12">
                    <Typography
                      component={"div"}
                      sx={{ fontSize: 20, marginBottom: 1 }}
                    >
                      APK To Add
                    </Typography>
                    <DropFileInput
                      onFileChange={(files) => handleFiles(files)}
                      accept=".apk,.xapk"
                      multiple={false}
                      files={Version.apkfile}
                    />
                  </div>
                  <div className="col-xl-3 col-md-6 col-12">
                    <Typography
                      component={"div"}
                      sx={{ fontSize: 20, marginBottom: 1 }}
                    >
                      Release Name
                    </Typography>
                    <Input
                      onChange={(e) => handleInputs(e)}
                      value={Version.version}
                      name="version"
                      type="Text"
                      autoFocus
                      label="Version"
                      placeholder="Version"
                      margin="normal"
                      fullWidth
                      sx={{ marginTop: 0 }}
                    />
                  </div>
                </div>
              </Box>
            </DialogContent>
            <DialogActions>
              <Fragment>
                <Box sx={{ float: "right" }}>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Button
                      onClick={() => {
                        Save();
                      }}
                      className="stepbtn"
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </Fragment>
            </DialogActions>
          </Dialog>

          <Fab
            onClick={handleClickOpen}
            title="Add Content"
            className="fixedplusbtn"
            color="primary"
            aria-label="add"
          >
            <AddIcon />
          </Fab>
        </Fragment>
      </Box>
    </>
  );
}

export default UploadFile;
