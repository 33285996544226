import React, { useState } from "react";
import {
  Button,
  CssBaseline,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  FormGroup,
  createTheme,
  ThemeProvider,
  IconButton,
  DialogTitle,
  Dialog,
  DialogContent,
} from "@mui/material";
import axios from "axios";
import { Input } from "../stories/Input/Input.jsx";
import LoadingSpinner from "./LoadingSpinner";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";

const theme = createTheme();

function Signup() {
  let navigate = useNavigate();
  const IsLogin = sessionStorage.getItem("token") ? true : false;
  const [user, setUser] = useState({ fullname: "", mobile: "" });
  const [OTP, setOTP] = useState("");
  const [IsTAndC, setIsTAndC] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [minutes, setMinutes] = useState(
    parseInt(`${process.env.REACT_APP_OTP_COUNTER_TIME_MINUTE}`)
  );
  const [seconds, setSeconds] = useState(
    parseInt(`${process.env.REACT_APP_OTP_COUNTER_TIME_SECOND}`)
  );
  const [intervalIds, setIntervalIds] = useState([]);

  //   if (IsLogin) window.location.href = "#/signin";

  const handleInputs = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleOTPs = (val) => {
    setOTP(val);
  };

  const handleTAndC = (e) => {
    setIsTAndC(e.currentTarget.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user.mobile) {
      toast.warn("Please enter mobile number.");
      return;
    }
    if (!IsTAndC) {
      toast.warn("Please check Terms & Conditions.");
      return;
    }

    setIsLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_APIURL}/users/mobile-sign-up`,
        { fullname: user.fullname, mobile: user.mobile },
        {}
      )
      .then(function (response) {
        if (response.data.status === 1) {
          toast.success(response.data.message);

          setMinutes(
            parseInt(`${process.env.REACT_APP_OTP_COUNTER_TIME_MINUTE}`)
          );
          setSeconds(
            parseInt(`${process.env.REACT_APP_OTP_COUNTER_TIME_SECOND}`)
          );
          setotptimer();
          setOpen(true);
        } else toast.warn(response.data.message);
        setIsLoading(false);
      })
      .catch(function (error) {
        toast.error(error.response.data.error.message);
        setIsLoading(false);
      });
  };

  const ResendOTP = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    sessionStorage.clear();
    await axios
      .post(
        `${process.env.REACT_APP_APIURL}/users/mobile-signin/${user.mobile}`,
        {},
        {}
      )
      .then(function (response) {
        if (response.data.status === 1) {
          toast.success(response.data.message);
          setOpen(true);
          setOTP("");
          setotptimer();
        } else toast.warn(response.data.message);
        setIsLoading(false);
      })
      .catch(function (error) {
        toast.error(error.response.data.error.message);
        setIsLoading(false);
      });
  };

  const clearAllInterval = async () => {
    while (intervalIds.length) {
      clearTimeout(intervalIds.pop());
    }
  };

  const setotptimer = async () => {
    clearAllInterval();
    let min = parseInt(`${process.env.REACT_APP_OTP_COUNTER_TIME_MINUTE}`),
      sec = parseInt(`${process.env.REACT_APP_OTP_COUNTER_TIME_SECOND}`);
    setMinutes(min);
    setSeconds(sec);

    const interval = setInterval(() => {
      if (sec === 0 && min === 0) clearInterval(interval);

      if (sec > 0) {
        sec = sec - 1;
        setSeconds(sec);
      }

      if (min > 0 && sec === 0) {
        min = min - 1;
        setMinutes(min);
        sec = 59;
        setSeconds(sec);
      }
    }, 1000);

    setIntervalIds((current) => [...current, interval]);
  };

  const VerifyOTP = async (e) => {
    e.preventDefault();

    if (OTP.length !== 6) {
      toast.warn("Please enter six digit otp.");
      return;
    }

    setIsLoading(true);
    await axios
      .patch(
        `${process.env.REACT_APP_APIURL}/users/otpverify/${user.mobile}/${OTP}`,
        {},
        {}
      )
      .then(function (response) {
        if (response.data.status === 1) {
          toast.success(response.data.message);
          setOpen(false);
          setOTP("");
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + response.data.result.token;
          sessionStorage.setItem(
            "token",
            "Bearer " + response.data.result.token
          );

          if (response.data.result.role === "User") { navigate("/home"); }
          else { navigate("/appstore"); }

          // window.location.reload();
        } else toast.warn(response.data.message);
        setIsLoading(false);
      })
      .catch(function (error) {
        toast.error(error.response.data.error.message);
        setIsLoading(false);
      });
  };

  const CloseOTP = () => {
    setOpen(false);
  };

  if (IsLogin) return <Navigate to="-1" replace />;

  return (
    <>
      <ThemeProvider theme={theme}>
        {isLoading ? <LoadingSpinner /> : <></>}

        <Box className="Loginbox">
          <Container
            component="main"
            maxWidth="xs"
            sx={{ boxShadow: 3, borderRadius: 5 }}
          >
            <CssBaseline />
            <Box
              sx={{
                padding: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box className="loginlogo">
                <Link to="">
                  <img src="../eye-logo.png" alt="eye-logo" border="0" />
                </Link>
              </Box>
              <Typography component="h1" variant="h5">
                Sign up
              </Typography>
              <Box component="form" sx={{ mt: 1 }}>
                <Input
                  onChange={(e) => handleInputs(e)}
                  name="fullname"
                  type="Text"
                  autoComplete="text"
                  autoFocus
                  label="Full Name"
                  placeholder="Full Name"
                  margin="normal"
                  fullWidth
                />
                <Input
                  onChange={(e) => handleInputs(e)}
                  maxLength={12}
                  name="mobile"
                  type="number"
                  autoComplete="text"
                  autoFocus
                  label="Mobile Number"
                  placeholder="Mobile Number"
                  required={true}
                  margin="normal"
                  fullWidth
                />
                <Grid container>
                  <FormGroup row className="mb-3">
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            handleTAndC(e);
                          }}
                          value="remember"
                          color="primary"
                          sx={{ color: "#1976d2" }}
                        />
                      }
                      label={
                        <p className="mb-0">
                          Agree to &nbsp;
                          <Link href="#/termsandconditioms" variant="body2">
                            <strong>Terms & Conditions</strong>
                          </Link>
                        </p>
                      }
                    />
                  </FormGroup>
                </Grid>
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mb: 2 }}
                >
                  Sign Up
                </Button>
                <Grid container>
                  <FormGroup row className="mb-0 mx-auto">
                    Already have an account? &nbsp;
                    <Link href="#/signin" variant="body2">
                      <strong>Sign In</strong>
                    </Link>
                  </FormGroup>
                </Grid>
              </Box>
            </Box>
          </Container>
        </Box>

        <Dialog open={open} className="dialog otpmodal" size="md">
          <DialogTitle>
            <IconButton
              onClick={CloseOTP}
              sx={{ position: "absolute", right: 8, top: 8, color: "#1976d2" }}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <a href="/" className="d-block m-auto" style={{ width: "115px" }}>
              <img
                src="../eye-logo.png"
                alt="eye-logo"
                border="0"
                className="mx-auto d-block"
              />
            </a>
            <Box>
              <p className="mt-4 text-center">
                Please Enter the OTP send on your Mobile Number.
              </p>
            </Box>
            <Box className="d-flex otpinput">
              <OtpInput
                value={OTP}
                onChange={handleOTPs}
                numInputs={6}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
                // separator={<span>-</span>}
              />
            </Box>
            <Box>
              {seconds > 0 || minutes > 0 ? (
                <p className="text-center fw-bold mt-3">
                  Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </p>
              ) : (
                <p className="text-center fw-bold mt-3">Didn't recieve code?</p>
              )}
            </Box>
            <Button
              onClick={ResendOTP}
              disabled={seconds > 0 || minutes > 0}
              className="text-center d-block m-auto rotp"
              style={{ cursor: "pointer", width: "115px" }}
            >
              Resend OTP
            </Button>
            {/* <Box><p className='text-center fw-bold mt-3'>Don't Receive an OTP</p></Box>
                        <a onClick={ResendOTP} className='text-center d-block m-auto rotp' style={{ cursor: 'pointer', width: '110px' }}>Resend OTP</a> */}
            <Button
              onClick={VerifyOTP}
              type="button"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
            >
              Submit
            </Button>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </>
  );
}

export default Signup;
